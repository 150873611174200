import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button, BoxProps, Box, Typography, Divider } from '@mui/material';
import { SimpleModal } from '../modal/SimpleModal';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

type Props = {
    buttonTitle: string,
    isNeedConfirm: boolean,
    onConfirm: () => void
} & BoxProps &
  PropsWithChildren;

function ConfirmDiscardDialog({ buttonTitle, isNeedConfirm, onConfirm, children, ...props }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmButton = () => {
    if (isNeedConfirm) {
        setIsOpen(true);
    } else {
        onConfirm();
    }
  }

  const handleModalConfirm = () => {
    onConfirm();
    setIsOpen(false);
  }

  return (
    <div>
      <Button
          variant="outlined"
          color="primary"
          onClick={handleConfirmButton}
        >
          {buttonTitle}
        </Button>
      <SimpleModal title={t("confirmDialog")} isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <Box {...props}>
            <Box 
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
            <Typography>
                {t("warning").toLocaleUpperCase()}
            </Typography>
            <ReportProblemIcon fontSize='large' sx={{ color: 'DarkOrange' }} />
          </Box>
          <Divider sx={{margin: '8px 0'}} />
          <Box>
            <Typography>
                {t("discardText")}
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={3}
            alignItems="right"
            justifyContent="end"
            sx={{ pt: 3 }}
          >
            <Button variant="contained" onClick={handleModalConfirm } sx={{ width: 200 }}>
              {buttonTitle}
            </Button>
            <Button variant="outlined" onClick={() => setIsOpen(false)} sx={{ width: 150 }}>
              {t('cancel')}
            </Button>
          </Stack>
        </Box>
      </SimpleModal>
    </div>
  );
}

export default ConfirmDiscardDialog;