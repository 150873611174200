import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "../components/app/App";
import { Resources } from "../components/pages/resources/Resources";
import { BrandingPage } from "../components/pages/branding/BrandingPage";
import { SchemasPage } from "../components/pages/content/schemas/SchemasPage";
import { BrandingEditPage } from "../components/pages/branding/edit/BrandingEditPage";
import { ProcessDefinitionPage } from "../components/pages/content/processDefinition/ProcessDefinitionPage";
import { DocumentTypesPage } from "../components/pages/content/documentTypes/DocumentTypesPage";
import { FacetsPage } from "../components/pages/content/facets/FacetsPage";
import { DocumentTypePage } from "../components/pages/content/documentTypes/DocumentTypePage";
import PermForDoc from "../components/pages/content/roles-and-permissions/permissions/impl/PermForDoc";
import { FacetPage } from "../components/pages/content/facets/FacetPage";
import PermissionsPage from "../components/pages/content/roles-and-permissions/permissions/Permissions";
import { LifeCyclesPage } from "../components/pages/content/life-cycles/LifeCyclesPage";
import LifeCyclePage from "../components/pages/content/life-cycles/LifeCyclePage";

import UsersGroups from "../components/pages/content/roles-and-permissions/users-and-groups/UsersGroups";
import { PackagesPage } from "../components/pages/packages/PackagesPage";
import PackagePage from "../components/pages/packages/PackagePage";
import ProfilePage from "../components/pages/profile/ProfilePage";
import SchemaPage from "../components/pages/content/schemas/SchemaPage/SchemaPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "packages",
        element: <PackagesPage />,
      },
      {
        path: "packages/:id",
        element: <PackagePage />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
      {
        path: "branding",
        element: <BrandingPage />,
      },
      {
        path: "branding/:id",
        element: <BrandingEditPage />,
      },
      {
        path: "content/schemas",
        element: <SchemasPage />,
      },
      {
        path: "content/schemas/:featureId",
        element: <SchemaPage />,
      },
      {
        path: "content/document-types",
        element: <DocumentTypesPage />,
      },
      {
        path: "content/document-types/:id",
        element: <DocumentTypePage />,
      },
      {
        path: "content/facets",
        element: <FacetsPage />,
      },
      {
        path: "content/facets/:id",
        element: <FacetPage />,
      },

      {
        path: "content/life-cycles",
        element: <LifeCyclesPage />,
      },
      {
        path: "content/life-cycles/:id",
        element: <LifeCyclePage />,
      },
      // {
      //   path: 'workflow/process-definitions',
      //   element: <ProcessDefinitionPage />,
      // },
      {
        path: "workflow/process-definitions",
        // element: <TestDND />,
        // element: <TestCanvas />,
      },
      {
        path: "roles-and-permissions/permissions",
        element: <PermissionsPage />,
      },
      {
        path: "roles-and-permissions/permissions/:featureId",
        element: <PermForDoc />,
      },
      {
        path: "roles-and-permissions/users-and-groups",
        element: <UsersGroups />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
    ],
  },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
